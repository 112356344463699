import React from 'react';
import './Wohoo';
const Contact = () => (
  <div className="text-center uk-animation-toggle">
    <button
      type="button"
      className="btn btn-primary float-right rounded-pill uk-animation-shake col-4 col-md-2"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#contact"
      aria-expanded="false"
      aria-controls="contact"
    >
      Contact Us
    </button>
    <div className="collapse justify-content-center" id="contact">
      <div className="card card-body bg-dark">
        <div class="row">
          <a
            href="https://wa.me/62895339403223?text=Axcoraapps%20saya%20mau%20informasi%20WebApp%0A"
            className="btn btn-success col-4 p-3 border border-dark"
            target="_blank"
          >
            Chat
          </a>
          <a
            href="tel:+62895339403223"
            className="btn btn-info col-4 p-3 border border-dark"
          >
            Call us
          </a>
          <a
            href="mailto:axcora@gmail.com"
            className="btn btn-danger col-4 p-3 border border-dark"
          >
            E-Mail
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
