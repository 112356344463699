import React from 'react';
import Contact from './Contact';
import { Link } from 'react-router-dom';

const Nav = () => (
  <div>
    <Link to="/">
      <img
        width="120"
        className="img-fluid"
        src="https://mesinkasironline.web.app/img/createwebsiteusingreact.png"
      />
      <h1 className="text-light">Sidoarjo Developer</h1>
    </Link>
    <p className="text-light">Pembuatan website dan aplikasi sidoarjo</p>
    <Contact />
  </div>
);

export default Nav;
