const articles = [
  {
    name: 'website',
    title: 'Pembuatan Website',
    desc:
      'Solusi pengembangan website yang terbaik dengan berbagai variant harga yang menarik',
    img:
      'https://1.bp.blogspot.com/-ZgThCBBOIXk/X-GyC1_93iI/AAAAAAAAL-g/KklJKtvnXhECd6PZg9vKQeHAyW0uyvowgCPcBGAYYCw/s741/aplikasisekolaherpaxcoraschool.png',
    content: [
      `Bersama kami untuk kemudahan dalam pembuatan website modern dengan berbagai technology tercipta untuk tujuan elegan dan mewah nya website anda, pembuatan website company profile, portfolio , art services , kuliner rumah makan cafe restoran, toko online shop, hingga pembuatan website sekolah untuk berbagai kebutuhan.`,
      `Desain unik dan menarik maupun desain dengan tema dan konsep by request custom terwujud bersama team developer kami, saat nya kini memiliki website terbaik untuk bisnis usaha dan sekolahan.`,
      `Bukan sekedar website dengan tampilan keren elegan dan mewah tetapi juga hadir sebuah aplikasi kasir penjualan maupun aplikasi invoice akuntansi online hingga aplikasi sekolah all in one untuk digunakan pada usaha dan bisnis hingga edukasi. price pembuatan website dan pengembangan di mulai dengan harga yang murah untuk digunakan Rp.368.800/tahun dengan domain my.id include cms source code dari kami untuk digunakan , hingga kebutuhan dengan domain .com Rp.568.800/tahun sudah termasuk desain menarik untuk anda, paket premium dengan template mewah hadir dengan harga Rp.1.562.800/tahun , atau gunakan premium paket yang terintegrasi dengan baik price Rp.3.268.400/tahun include apk android.`,
      `Untuk technology jangan kawatir kami menyediakan beragam technology untuk memudahkan anda dalam memilih framewrok untuk digunakan , pembuatan website laravel , pembuatan website symfony, pembuatan website angular, pembuatan website react, pembuatan website gatsbyjs,pembuatan website jekyll ruby, dan tentunya content management system untuk digunakan mulai dari get axcora cms, MODX revolutions, Grav , Wordpress, Blogspot custom, native bootstrap dan lain sebagai nya.`,
      `bersama kami kini saat nya maju dan berkembang.`
    ]
  },
  {
    name: 'aplikasi',
    title: 'Pembuatan Aplikasi',
    desc:
      'Terbaik untuk kebutuhan aplikasi pembukuan bisnis dan edukasi dengan variant technology digunakan.',
    img:
      'https://1.bp.blogspot.com/-EJeyP2C2wC0/X-Gx9cTy2bI/AAAAAAAAL-g/almc17ny1awHGUNG1UwZTjZUecgQsIyEQCPcBGAYYCw/s473/ftp3-1.png',
    content: [
      `Layanan pembuatan aplikasi dalam kebutuhan untuk pembukuan dan mempercepat transaksi dengan clouds technology online system memudahkan dan semakin mobile dalam penggunaan nya, via clouds installasi langsung di awan maka memudahkan untuk akses dari berbagai device dalam bekerja baik via smartphone pc desktop laptop dan lain sebagai nya, unlimited user bekerja memudahkan untuk karyawan staff bekerja dengan cepat.`,
      `Aplikasi kasir toko penjualan dilengkapi dengan inventori stok management, laporan detail dan tentunya desain kekinian menjadikan lebih mewah dan sangat mengasyikan bekerja dengan aplikasi toko kasir penjualan ini, aplikasi restoran cafe rumah makan memudahkan dalam kontrol secara cepat baik untuk waiters via mobile waiters app bekerja mencatat pesanan pelanggan mengirimkan pesanan ke kitchen dan kasir bertransaksi untuk mencetak struk kasir hingga bill sesuai kebutuhan , selanjutnya memudahkan admin kontrol berbagai laporan yang dikelola nya, untuk office perkantoran tersedia invoice faktur pajak penjualan dengan system pencatatan tender yang dikerjakan, agenda project, dan untuk kebutuhan akuntansi accounting yang benar benar mudah untuk penggunaan nya.`,
      `Aplikasi sekolah semakin memudahkan dalam proses belajar mengajar dengan cepat via ERP sekolah baik untuk e-learn online, tugas ujian online, absensi , pembayaran keuangan sekolah, dan berbagai aplikasi sekolah siap dalam penggunaan nya bekerja dengan baik untuk menunjang proses edukasi.`,
      `Konsultasikan kebutuhan anda bersama kami untuk kemudahan sebuah aplikasi membantu anda dalam bekerja.`
    ]
  },
  {
    name: 'Android',
    title: 'APK Android',
    desc:
      'Semakin modern dengan aplikasi android untuk bisnis usaha dan edukasi',
    img:
      'https://1.bp.blogspot.com/-5W-s6OdRdOg/X-Gsh-iPRgI/AAAAAAAAL7g/99cXgcWiZMk9v_4vu5I8my4uVkasDiCdgCLcBGAsYHQ/s830/mesinkasironlinenew.png',

    content: [
      `Layanan pembuatan aplikasi android semakin memudahkan dalam dekat dengan pelanggan tentunya juga sebagai media promosi online dengan apk android anda dapat menyebarkan secara luas baik via playstore maupun ke website anda sendiri untuk di unduh oleh pelanggan`,
      `Berbagai technology dalam penerapan nya mulai dari flutter dan dart untuk kebutuhan develope aplikasi android , hingga java kotlin untuk kebutuhan webview agar benar benar terintegrasi dengan website hingga aplikasi online dalam operasionalnya, miliki aplikasi android anda sendiri untuk menambah kepercayaan pelanggan.`
    ]
  },
  // {
  //   name: "pembuatanwebsite",
  //   title: "Modern website",
  //   desc: "Hei.. ada bonus website gratis",
  //   img:
  //     "https://1.bp.blogspot.com/-eZ13VXar8C8/Xzt058wV1nI/AAAAAAAALOM/9RglhUNOakwoxl3GoeANXmwX-sq1B6ukACLcBGAsYHQ/s2048/pembuatan%2B//website%2Bmodern%2Bdengan%2Blaravel.jpg",
  //   content: [
  //     `Yap.. dengan berlangganan layanan larapos apps secara otomatis kamu bisa mendapatkan sebuah website yang berguna untuk media //promosi bisnis mu di dunia online net, dan tentu sangat bermanfaat untuk digunakan sebagai media marketing digital bisnis //usaha mu, untuk memperkenalkan toko usaha mu secara luas via website milikmu.`,
  //     `Tersedia berbagai website disesuaikan dengan pilihan paket layanan, basic dengan simple website informasi , dan paket //standard dengan get axcora cms modern app kami, atau menggunakan laravel all in one menyempurnakan dalam membalut aplikasi //include dengan website plus domain nama toko mu`
  //   ]
  // },
  // {
  //   name: "getstart",
  //   title: "Lets Get Started Now",
  //   desc: "Mulai gunakan larapos sekarang",
  //   img:
  //     "https://1.bp.blogspot.com/-8TDWnq41TuE/Xzt03g86CoI/AAAAAAAALN4/RRO9T2k3or8x4xx6vBDAmdn0lIHLU6oIACLcBGAsYHQ/s1500/aplikasi%2Bt//oko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25282%2529.jpg",
  //   content: [
  //     `Dan kini saat nya memanjakan diri dengan pembukuan yang aktual dengan simple system mudah digunakan serta lengkap dalam //menunjang kebutuhan usaha dan bisnis mu, larapos menjadi solusi terbaik pada semua usaha dengan kemampuan penjualan retail //dan grosir dengan display pos tersendiri semakin memudahkan setiap transaksi baik grosir dan eceran retail.`,
  //     `Klik download untuk cek harga larapos web apps ini.`
  //   ]
  // },
  // {
  //   name: "loginaplikasitoko",
  //   title: "Login menu",
  //   desc: "Keamanan aplikasi dengan login",
  //   img:
  //     "https://1.bp.blogspot.com/-6v63PY3r1_c/Xzt0_JUmcNI/AAAAAAAALOU/oATUQmFaBVg_dj1TmqIkZVvfwJ4o2_YzwCLcBGAsYHQ/s1349/aplikasi%2Bt//oko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25285%2529.png",
  //   content: [
  //     `Hadir halaman login awal untuk akses aplikasi kasir ini, dengan multi proteksi akan penggunaan password dan username baik //via aplikasi , database dan cloud host semakin aman dan nyaman dalam menggunakan aplikasi ini`,
  //     `Dukungan penuh lets encrypt maupun cloudflare memprotkesi secara penuh security apps ini di cloud online.`
  //   ]
  // },
  // {
  //   name: "fituraplikasitoko",
  //   title: "Fitur lengkap",
  //   desc: "Kelengkapan fitur menunjang pembukuan",
  //   img:
  //     "https://1.bp.blogspot.com/-LOo12q1NWsM/Xzt0_I0l6dI/AAAAAAAALOQ/r5AgzZUOVRMN4eEw2S1UPo99pEGRTwUSwCLcBGAsYHQ/s1020/aplikasi%2Bt//oko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25284%2529.png",
  //   content: [
  //     `Dengan fitur yang lengkap memudahkan aktivitas bekerja dengan menggunakan aplikasi toko grosir dan eceran ini, master //database dalam kebutuhan input dan register pendaftaran user, satuan produk, hingga input data produk `,
  //     `Mudah digunakan dan cepat untuk proses input data.`
  //   ]
  // },
  // {
  //   name: "panelpenjualan",
  //   title: "Panel Penjualan",
  //   desc: "Memudahkan dalam penjualan grosir dan ecer",
  //   img:
  //     "https://1.bp.blogspot.com/--BsMUVZN5II/Xzt1GqD6MHI/AAAAAAAALOg/vQznUpeH5hcg5B7OwafIDxtbWSfl2ReWwCLcBGAsYHQ/s1103/aplikasi%2Bt//oko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%252814%2529.png",
  //   content: [
  //     `Semakin sempurna pada display pos point of sale penjualan yang dengan mudah untuk memilih dan melakukan transaksi via gorsir //dengan harga khusus grosir maupun retail dengan harga khusus untuk kebutuhan penjualan retail pada menu panel penjualan ini `,
  //     `Dalam pelaksanaan nya tinggal klik dan pilih panel penjualan sesuai dengan kebutuhan dalam bertransaksi, secara otomatis pos //system akan menggunakan harga sesuai dengan panel pilihan pada penjualan.`
  //   ]
  // },
  // {
  //   name: "pointofsale",
  //   title: "Point of sale",
  //   desc: "Fitur penjualan kasir display POS",
  //   img:
  //     "https://1.bp.blogspot.com/-KNIFpcGse7s/XzuGwjEI4JI/AAAAAAAALO4/W06_zV8WM5UOdBCV6yvjmqWcpWeIh0JkwCLcBGAsYHQ/s1162/aplikasi%2Bt//oko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%252813%2529.png",
  //   content: [
  //     `Point of sale kasir display yang mudah dalam penggunaan nya, dan dilengkapi dengan pencarian produk memudahkan dalam setiap //transaksi sesuai kebutuhan.`,
  //     `Dan kemudahan dalam cetak struk kasir bukti transaksi penjualan kasir kepada pelanggan menyempurnakan system mesin kasir //online ini bekerja.`
  //   ]
  // },
  {
    name: 'webapp',
    title: 'Website Application',
    desc:
      'Website dan aplikasi dalam satu apps untuk berbagai kebutuhan bisnis usaha dan edukasi sekolah.',
    img:
      'https://1.bp.blogspot.com/-_t509qsqLz8/X-IOO5-R7MI/AAAAAAAAL_0/nnYc00Fig6USqy6Hw4H9IGd80cwjgwg2wCLcBGAsYHQ/s581/aplikasi.png',
    content: [
      `Dan sentuhan dengan all in one terintegrasi dengan baik menggunakan web applikasi kami , apa itu web app ?? website application adalah generasi terbaru dan banyak digunakan dalam menunjang berbagai kebutuhan baik bisnis maupun edukasi sekolah dalam bekerja, dimana pada web app ini hadir dengan fitur aplikasi untuk bekerja yang terintegrasi dengan website secara langsung untuk kemudahan dalam mobilitas full online dalam digunakan, dengan menggunakan web app maka bekerja dengan aplikasi lebih cepat hingga kebutuhan update website semakin lebih mudah.`
    ]
  },
  {
    name: 'allinapps',
    title: 'All in one aplikasi',
    desc:
      'All in one aplikasi clodus online terintegrasi dengan website hingga APK android.',
    img:
      'https://1.bp.blogspot.com/-eyMWUw_SubY/X-Gx3QFTBOI/AAAAAAAAL-c/ORKoDUwtRKga7fkWWoFnKfSQwBmbO7GZwCPcBGAYYCw/s1980/aplikasisekolah%2Bonline%2Bweb%2Bapp%2B%25281%2529.png',
    content: [
      `Sebuah perkembangan dan revolusi dari website application dengan all in one apps ini tercipta dengan tujuan kemudahan dalam bekerja hingga proses aplikasi digunakan, semakin sempurna dengan bekerja dengan aplikasi untuk pembukuan dan transaksi hingga pencatatan detail kinerja usaha bisnis, maupun edukasi , plus cepat dalam update artikel website secara lansgung, dan tentunya balutan aplikasi APK android melengkapi segala nya dengan all in one konsep tercipta maka lengkap sudah berbagai sector untuk digunakan agar semakin mobile dan cepat .`
    ]
  }
];

export default articles;
